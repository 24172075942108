import React, { Component } from "react";

class About extends Component {
    render() {
        let title = 'About',
            description = 'At Aathisoft Technologies, we provide solutions that are "Innovative & Intelligent" in addressing the critical business needs of Mid to Large Corporate spread across business verticals and geographies. Advanced Technology coupled with enriched functionalities helps our customers towards sustained business growth. Aathisoft Solutions guarantee multifold Return on Investment At Aathisoft Technologies, We are Technology Partners to our Clients rather than a Software Vendor by creating the difference of bringing our Multi-Content exposure, niche domain experience, Advantages of working with Global Industry Leaders, Technology superiority and our learnings of best practices across various solution domains that helps implementing software solutions that are futuristic and enable enterprises.';
        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-6.jpg" alt="About Images" />

                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    {/* <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;