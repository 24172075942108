import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Inventory Management',
        description: 'Ensures the complete life cycle of asset and accurate history has to be maintained, leaves the integrity of the asset and evidence.'
    },
    {
        icon: <FiMonitor />,
        title: 'Maintenance Management',
        description: 'Continuous process to improve the availability, safety and expected lifecycle of assets is performed throughout its lifecycle from procurement to disposal.'
    },
    {
        icon: <FiCast />,
        title: 'Work Order Management',
        description: 'Helps to centralize the work requests, offers visible to the entire management team. And makes jobs more efficient and improves the response time.'
    },
    {
      icon: <FiUsers />,
      title: 'Contact Management',
      description: 'Centralized repository to holds all relevant data of a contact, along with specific groups, industry sector, billing, instructions etc.,'
  },
  {
      icon: <FiMonitor />,
      title: 'Serve your clients',
      description: 'Access the Legal Software support through Helpdesk, Online Live chat etc., and various options are available ensures to operate more efficiently.'
  },
  {
      icon: <FiCast />,
      title: 'Legal Document Creation',
      description: 'Creates automated legal forms and documents with ease & saves time by entering the legal client data once in a cloud.'
  },
]

class BusinessProcess extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--38"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Business Process Mangement
                  </h2>
                  <p> Information and things to produce business</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/bpm1.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h3>Business Process Mangement</h3>
                          <p>
                            BPM Software (BPMS) is a process automation tool. It helps you map out your everyday processes to identify and eliminate bottlenecks, control your company’s costs, make your day-to-day processes as efficient as possible, and ensure the effectiveness of the people involved in your processes.
                          </p>
                          <p>•	Modeling – Map out your process and measure the time and cost needed to execute your process.<br></br>
                            •	Automating - Evolve your process from a manual process to an electronic process where data is collected in web forms and easily transferred to/from your existing third-party applications.<br></br>
                            •	Managing – Gain insight into your processes, the business data in those processes, and the effectiveness of the process participants.<br></br>
                            •	Optimization – Learn how to make your day-to-day business process more efficient, more cost-wise, and control data entry and application errors.<br></br>
                          </p>


                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row service-one-wrapper">
                      {ServiceList.map((val, i) => (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                          <a href="/service-details">
                            <div className="service service__style--2">
                              <div className="icon">
                                {val.icon}
                              </div>
                              <div className="content">
                                <h3 className="title">{val.title}</h3>
                                <p>{val.description}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>

                    <br></br>
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">FEATURES</h4>
                          <ul className="liststyle">

                            <li>Reduced labour Cost</li>
                            <li>Improved Productivity</li>
                            <li>Higher Customer Satisfaction</li>
                            <li>Project Management Integration</li>
                            <li>No redundant and monotonous work</li>
                            <li>Work Completion at the faster rate</li>
                            <li>Improves visibility and control of your business processes</li>
                          </ul>
                          <br></br>
                          <h4 className="title">Benefits of Business Process Management </h4>
                          <ul className="liststyle">
                            <li>Risk reduction.</li>
                            <li>Increased agility.</li>
                            <li>Ensured compliance.</li>
                            <li>Better organisational control.</li>
                            <li>Overview of organisational goals.</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/bpm2.jpg"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="vqeOHUzkrqg"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default BusinessProcess;
