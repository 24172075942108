import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList = [

    {
        icon: <FiUsers />,
        title: 'Mobile App Development',
        description: 'Marketing reporting is the process of gathering and analyzing marketing metrics to inform future marketing decisions, strategies, and performance. Marketing reports uncover meaningful, with in actionable goals.'
    },
    {
        icon: <FiMonitor />,
        title: 'Web Development',
        description: 'Mobile application development is the process of creating software applications that run on a mobile device, and a typical mobile application utilizes a network connection to work with remote computing resources.'
    },
    {
        icon: <FiCast />,
        title: 'Artificial Intelligence ',
        description: 'Software is a tool that automates invoice generation for goods and services rendered. The integrated tools create a list of products and services and their related costs per alculations respective recipient.'
    },
    

]
const DigitalMarketing = [
    {
        icon: <FiUsers />,
        title: 'Marketing',
        description: 'Marketing reporting is the process of gathering and analyzing marketing metrics to inform future marketing decisions, strategies, and performance.'
    },
    {
        icon: <FiMonitor />,
        title: 'Feature',
        description: 'Costs, Longevity, Entation, Buying intent, Customer Engagement, Measuring Profitability, Defining the Target Audience.'
    },
    {
        icon: <FiCast />,
        title: 'Benefits',
        description: 'Gain Brand Credibility,Target the Right Audience,Accurately Measure All Results,Generate a Consistent Lead Pipeline,Engage the Customer at Every Buying Stage.'
    },
]

const Webdevelopment = [
    {
        icon: <FiUsers />,
        title: 'MobileApp Development',
        description: 'Hence, the mobile app development process requires creating software that can be installed on the device.'
    },
    {
        icon: <FiMonitor />,
        title: 'Feature',
        description: 'Mobile Compatibility,Accessible to All Users,Fast Load Times,Browser Consistency,Responsive Design,Well Planned Information Architecture.'
    },
    {
        icon: <FiCast />,
        title: 'Benefits',
        description: 'A Website Helps Establish Brand Identity,You Need a Website for Digital Marketing,Small Business Web Development Drives Sales,Organic Traffic with A Website.'
    },
]
class Service extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Service'} />
                {/* End Breadcrump Area */}


                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Service</h2>
                                    <p>Customizable Retail Billing Software to Expand your<br />Retail Business Retail Software.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                    <p>Now, the world has moved towards digitization and traditional<br />Marketing methods are becoming obsolete now.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {DigitalMarketing.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>MobileApp Development</h2>
                                    <p>Mobile app development is a process for building <br />mobile applications that run on mobile devices.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {Webdevelopment.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Development</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;