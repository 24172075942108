import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Automated Lead Generation',
        description: 'Capture leads from different marketing portals like Facebook, Twitter, Website, chat, web forms and Emails. After the lead nurtures, automatically assigns to salesperson.'
    },
    {
        icon: <FiMonitor />,
        title: 'Marketing Automation',
        description: 'Omni-channel Marketing automation strategy to convert new leads to conversions. Drive the relationships with customers.'
    },
    {
        icon: <FiCast />,
        title: 'Analytical Reporting',
        description: 'Generate 135+ reports and helps to measure effectiveness of each process. Enhance their process in Sales and Marketing investment, evaluate the quality of leads and improves their measurement.'
    },
    {
        icon: <FiUsers />,
        title: 'Document Management',
        description: 'Access, create, edit and share the documents with clients or colleagues directly from the nTireCRM.'
    },
    {
        icon: <FiMonitor />,
        title: 'Analysis CRM',
        description: 'Analyze the stage of lead automation, alignment, prioritization, scheduling, tracking etc., Powerful feature allows to calculate the return on Investment, closure ratios etc.,'
    },
    {
        icon: <FiCast />,
        title: 'Intelligent Routing',
        description: 'Companies may differ in assigning leads to salesperson. New opportunities can be assigned and routed to the respective reps mail based on pre-determined characteristics.'
    },
]


class sales extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--41" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">SALES</h2>
                                    <p>Grow your business & marketing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/sales1.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h2>Sales</h2>
                                                    <p>Sales is a term used to describe the activities that lead to the selling of goods or services. Businesses have sales organizations that are broken up into different teams. And these sales teams are often determined based on the region they're selling to, the product or service they're selling, and the target customer.</p>
                                                    <p>Sales management is the process of planning, recruiting, training, organizing, supervising, and coordinating an organization's sales force. Whether you are selling a service or a product, sales managers are responsible for managing the sales force, setting the objectives for the team, planning and controlling the entire sales process, and ultimately ensuring the execution of the team vision. More than any other group in an organization, the role of a sales team is probably the most crucial one, since it has a direct impact on an organization's revenue.</p>
                                                    {/* <p>One of the most important functions that you have as a sales manager is to set up and implement a sales process for your business. Let's take a deeper look at crafting the perfect sales process for your business.</p> */}

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">


                                                        <li> Analytics, Dashboard</li>
                                                        <li>Gain experience within sales.</li>
                                                        <li>Always have an elevator pitch.</li>
                                                        <li>Be educated about sales principles.</li>
                                                        <li>Sales Order Management, Contact Management.</li>
                                                        <li>Understand the product and its benefits.</li>
                                                        <li>Know the customer before you pitch them.</li>
                                                        <li>Have your customer's best interests in mind.</li>
                                                    </ul>
                                                    <h4 className="title">Benefits of Sales Management </h4>
                                                    <ul className="liststyle">
                                                        <li>Save Time.</li>
                                                        <li>Promote Teamwork.</li>
                                                        <li>Automate Workflow.</li>
                                                        <li>Exceed Sales Targets.</li>
                                                        <li>Stay Up-to-Date on Your Pipeline.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/sales2.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='3ib3akiVsaw' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default sales;