
import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Office Account Billing',
        description: 'Saves Valuable time and reduction of delay in the firm and creates easily accessible financial information is correct and updated.'
    },
    {
        icon: <FiMonitor />,
        title: 'Billing and Payments',
        description: 'In a single click, Billing and payments makes it easier than ever. Manages the schedule payments and notifies the automatic reminders for overdue.'
    },
    {
        icon: <FiCast />,
        title: 'Practice Management',
        description: 'Billing software is a tool that automates invoice generation for goods and services rendered. The integrated tools create a list of products and services recipient.'
    },
    {
        icon: <FiUsers />,
        title: 'Document Creation',
        description: 'Creates automated forms and documents with ease & saves time by entering the legal client data once in a cloud.'
    },
    {
        icon: <FiMonitor />,
        title: 'Serve your clients',
        description: 'Access the Legal Software support through Helpdesk, Online Live chat etc., and various options are available ensures to operate more efficiently.'
    },
    {
        icon: <FiCast />,
        title: 'Workflow automation',
        description: 'Centralized repository to holds all relevant data of a contact, along with specific groups, industry sector, billing, instructions etc.,'
    },
]

class BillingSoftware extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--40" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">BILLING SOFTWARE</h2>
                                    <p>Create professional Invoices, Track Dues & Manage your accounts easily</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/billing1.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>BILLING SOFTWARE</h3>
                                                    {/* <p>Customizable Retail Billing Software to Expand your Retail Business. Manage all your retail operations in a centralized system with Marg Retail Software. From purchasing to inventory management to selling, stay on top of everything & increase your sales by attracting more customers.</p> */}
                                                    <p>Billing software, commonly known as invoicing software, can be any software designed to simply generate invoices for products and services rendered to customers. Billing software also comes with the ability to track the payment receipts from customers against the invoices issued.</p>

                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">

                                                        <li>Loyalty Point</li>
                                                        <li>Easy & Fast Billing</li>
                                                        <li>Discounts & Schemes</li>
                                                        <li>Re-Order Management</li>
                                                        <li>Barcode Management</li>
                                                        <li>Inventory Management</li>
                                                        <li>Bills receivable management.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}

                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>

                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Benefits of Using Billing Software</h4>
                                                    <ul className="liststyle">

                                                        <li>Unlimited Cloud-Storage</li>
                                                        <li>Ideal for Brand Identity</li>
                                                        <li>Multi-currency Feature</li>
                                                        <li>Accessible from Anywhere</li>
                                                        <li>Fewer Errors and Quick Audits</li>
                                                        <li>Generate Invoice Within Minutes</li>
                                                        <li>Faster Billing, Instant Payments</li>
                                                        <li>Easily Manage Your Business Operations</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/billing2.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='fEJvl_UGQnM' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BillingSoftware;