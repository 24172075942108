import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
//import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiChevronUp } from "react-icons/fi";

class Refundandcancellation extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    REFUND & CANCELLATION
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}

        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}

                    {/* End Single Area */}

                    {/* Start Single Area */}

                    <div>
                      <div>
                        <div>
                          {/* <p>but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing</p> */}

                          <h3>Cancellation Policy</h3>
                          <p>
                            Please note an order can only be canceled within 24
                            hours of placing the order. Once the order is
                            processed after 24 hours, no cancellation request
                            will be entertained. However return is possible for
                            all orders/products.
                          </p>
                          <h3>OR</h3>
                          <p>
                            Customers can CANCEL order only before the Order has
                            been shipped/Dispatched. After the Product/s have
                            been shipped, The Customer CANNOT Cancel the Orders.
                            However return is possible for all orders/products.
                          </p>
                          <h3>Shipping & amp; Delivery Policies -</h3>
                          <p>
                            ---Aathisoft--- ships its products to almost all
                            parts of India. Orders placed will be shipped within
                            24* hrs. We ship on all days except Sunday and
                            National Holidays. For all areas serviced by reputed
                            couriers, the delivery time would be within 3 to 4
                            business days of shipping (business days exclude
                            Sundays and other holidays).
                          </p>
                          <p>
                            For other areas the products will be shipped through
                            parcel service, "ST courier", "Professional courier" and other couriers are provider and
                            may take 1-2 weeks depending on location. At times
                            there might be unexpected delays in the delivery of
                            your order due to unavoidable and undetermined
                            logistics challenges beyond our control for which
                            --Aathisoft--is not liable and would request its
                            users to cooperate as --Aathisoft-- continuously
                            tries to nought such instances.
                          </p>
                          <p>
                            Also, ---Aathisoft-- reserves the right to cancel
                            your order at its sole discretion in cases where it
                            takes longer than usual delivery time or the
                            shipment is physically untraceable and refund the
                            amount paid for cancelled product(s) to your source
                            account.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Refundandcancellation;
