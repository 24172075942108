import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'DIGITAL MARKETING',
        description: 'The term digital marketing refers to the use of digital channels to market products and services in order to reach consumers. This type of marketing involves the use of websites, social media, search engines and other similar channels. Digital marketing became popular with the advent of the internet in the 1990s.'
    },
    {
        icon: '02',
        title: 'WEBSITE DEVELPOMENT',
        description: 'Web development refers in general to the tasks associated with developing websites for hosting via intranet or internet. The web development process includes web design, web content development, client-side/server-side scripting and network security configuration, among other tasks.'
    },
    {
        icon: '03',
        title: 'MOBILE APP DEVELOPMENT',
        description: 'Mobile application development is the set of processes and procedures involved in writing software for small, wireless computing devices, such as smartphones and other hand-held devices.Like web application development, mobile application development has its roots in more traditional software development.'
    },
]

class ServiceOne extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    {ServiceList.map((val, i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency" />
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;