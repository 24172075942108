import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Web Development',
        link:"/service-webDeveploment",
        description: 'Easily Create, Customize, and Promote A Stunning Website With tasks associated with developing websites.'
    },
    {
        icon: <FiLayers />,
        title: 'Mobile App Development',
        link:"/service-mobileappdevelopment",
        description: 'Mobile app crash reporting and run-time errors in a single view. Measure the health of mobile releases over time and view.'
        
    },
    {
        icon: <FiUsers />,
        title: 'Digital Marketing',
        link:"/digital-marketing",
        description: 'The term digital marketing refers to the use of digital channels to market products and services in order to reach consumers.'
    },
    {
        icon: <FiMonitor />,
        title: 'Website Development',
        link:"/service-webDeveploment",
        description: 'Web development refers in general to the tasks associated with developing websites for hosting via intranet or internet.'
    },
    {
        icon: <FiUsers />,
        title: 'Chatbot Development',
        
        description: 'Customer service is the support you offer your customers both before and after they buy and use your products or services.'
    },
    {
        icon: <FiMonitor />,
        title: 'Marketing & Reporting',
        link:"/service",
        description: 'Supplement your affiliate marketing strategy with search, display & video ads in your products or services.'
       
    }
]


class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <a href={val.link}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
