import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Purchase Requisitions',
        description: 'Speed up your procurement processes – from requisition to receipts. Easily create Purchase Requisition directly from product lists.'
    },
    {
        icon: <FiMonitor />,
        title: 'Workflow approval',
        description: 'Enables synergies across departments for better workflow. Handles the responsibility of moving documents involved in a procurement function.'
    },
    {
        icon: <FiCast />,
        title: 'Supplier Management',
        description: 'Supplier Management starts with unique Procurement solution with smart bidding algorithms that enhances their engagement.'
    },
    {
        icon: <FiUsers />,
        title: 'Integrations',
        description: 'Smart Integrations with a centralized dashboard and its flexible to integrate with SAP through 3rd party approval.'
    },
    {
        icon: <FiMonitor />,
        title: 'Vendor Management',
        description: 'Quicker registration by accessing their vendor information through Multilingual catalogues. Rate your vendors using KPI’s.'
    },
    {
        icon: <FiCast />,
        title: 'Mobile',
        description: 'Run your eProcurement seamlessly on desktop, iOS and Android. Request, approve or receive PO in a click through Mobile and gets notified.'
    },
]


class Leads extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--42" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Leads Management System</h2>
                                    <p>Drive more sales with our effective lead management system</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/lead-workflow.png" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>LEADS</h3>
                                                    <p>Lead management is a systematic process in which incoming leads are qualified, analyzed, and nurtured so that they can be converted into new business opportunities. In a typical sales process, leads from multiple channels enter your lead management system, and the sales-ready leads are converted into deals.</p>
                                                    <p>Lead management is the strategy that sales teams use to understand which stages of the pipeline their deals are in. This tracking helps sales teams know whether a lead or prospect is on a path to closing.</p>
                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">
                                                        <li>MultiLinqual,Lead Scoring.</li>
                                                        <li>Distribute leads automatically.</li>
                                                        <li>Discover new leads from Facebook.</li>
                                                        <li>From leads to happy, engaged customers.</li>
                                                        <li>Create and send targeted email campaigns.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Benefits of a Lead Management</h4>
                                                    <ul className="liststyle">
                                                        <li>Sharper Insights.</li>
                                                        <li>Real-Time Reporting.</li>
                                                        <li>Better Filter Your Leads.</li>
                                                        <li>Impactful Lead Nurturing.</li>
                                                        <li>Efficient Lead Tracking.</li>
                                                        <li>Improve Team Coordination.</li>
                                                        <li>Be More Productive with Automation.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/procurement2.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='Ap4Wnk_iixM' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Leads;