import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function SimpleSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoScroll: {
            speed: 1,
        },
      autoplaySpeed: 2000,
      cssEase: "linear"
    };
    return (
        <Slider {...settings}>
            <div>
                <img src="/assets/images/service/logo-pdf1.jpg" />
            </div>
            <div>
                <img src="/assets/images/service/logo-pdf2.jpg" />
            </div>
            <div>
                <img src="/assets/images/service/logo-pdf3.jpg" />
            </div>
            <div>
                <img src="/assets/images/service/logo-pdf4.jpg" />
            </div> <div>
                <img src="/assets/images/service/logo-pdf5.jpg" />
            </div>
            <div>
                <img src="/assets/images/service/logo-pdf6.jpg" />
            </div> <div>
                <img src="/assets/images/service/logo-pdf7.jpg" />
            </div>
            <div>
                <img src="/assets/images/service/logo-pdf8.jpg" />
            </div> <div>
                <img src="/assets/images/service/logo-pdf9.jpg" />
            </div>
            <div>
                <img src="/assets/images/service/logo-pdf10.jpg" />
            </div> <div>
                <img src="/assets/images/service/logo-pdf11.jpg" />
            </div>
            <div>
                <img src="/assets/images/service/logo-pdf12.jpg" />
            </div> <div>
                <img src="/assets/images/service/logo-pdf13.jpg" />
            </div>
            <div>
                <img src="/assets/images/service/logo-pdf14.jpg" />
            </div>
        </Slider>
    );
}