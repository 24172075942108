import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'pay Optimization',
        description: 'Starts from the needs analysis to the purchase requisitions and PO’s through the payment of the final vendor invoice, the Procurement Management Software.'
    },
    {
        icon: <FiMonitor />,
        title: 'Supplier Management',
        description: 'Your supply chain depends on the relationship with vendors who offers goods and services that helps to run the business. An effective Procurement Management System.'
    },
    {
        icon: <FiCast />,
        title: 'Sourcing',
        description: 'Purchase goods and services from external resources for effective functioning of Organization. Plays a crucial part in the company’s overall business strategy.'
    },
    {
      icon: <FiUsers />,
      title: 'Negotiation',
      description: 'Skilled Professionals negotiate the best deal and should procure the highest quality of products with the best possible price.'
  },
  {
      icon: <FiMonitor />,
      title: 'Monitoring Suppliers',
      description: 'Monitor contracts to ensure the efficient supply of goods and services from suppliers timely delivery of materials from vendors.'
  },
  {
      icon: <FiCast />,
      title: 'Business Protocols',
      description: 'Procurement function must ensure to meet all company objectives and requirements.'
  },
]

class ProductPropertyManagement extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--43"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Property Management</h2>
                  <p>The Best Resources</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
       
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/propertymanagement.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h3>Property Management</h3>
                          <p>
                            {" "}
                            Comprehensive Property Management Software that
                            supports you to do more than just managing the
                            properties. You can handle right from identification
                            of the Property, legal compliance checking and
                            entering into Lease/Rental Agreement. Here the
                            nTireFM are available at the market and the same
                            solution can't be implemented for every business.
                            Aathisoft's Property Management Software in India is
                            a web-based platform that enables land owners and
                            real estate professionals to design their unique
                            solution by drag and drag facility and helps them to
                            run quickly.
                          </p>
                          {<p>
                            Property Management Software in India helps in
                            tracking the movement of all physical documents of
                            the properties at any point in time. Finally,
                            Property Management Software gives you the clarity
                            to focus on what matters the most. Designed smart
                            and automated so you can have the time to enhance
                            your business and scale your vision.
                          </p>}


                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                        <br></br>

                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Features</h4>
                          <ul className="liststyle">

                            <li>Property Lease / Rent Terms.</li>
                            <li>Multi Language / Multi Language.</li>
                            <li> Resident Site & Owner portal.</li>
                            <li>Asset Management & Multi Tenancy.</li>
                            <li>Generation of Payables / Receivables.</li>
                            <li>Enterprise Workflow Manager & Audit Log.</li>
                            <li> Property Accounting & Company Financials.</li>
                            <li>Analytics & Insights / Generation of Agreements.</li>
                            <li>Notification Manager for SMS & Email Notifications.</li>
                            <li> Retail Cash Payments & Property Information System.</li>
                          </ul>
                          <h4 className="title">Benefits of Property Management</h4>
                          <ul className="liststyle">
                            <li>Yet this above sewed flirted opened ouch</li>
                            <li>Goldfinch realistic sporadic ingenuous</li>
                            <li>
                              Abominable this abidin far successfully.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/pm.jpg"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="vqeOHUzkrqg"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ProductPropertyManagement;
