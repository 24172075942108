import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";

import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Purchase Requisitions',
        description: 'Speed up your procurement processes – from requisition to receipts. Easily create Purchase Requisition directly from product lists.'
    },
    {
        icon: <FiMonitor />,
        title: 'Workflow approval',
        description: 'Enables synergies across departments for better workflow. Handles the responsibility of moving documents involved in a procurement function.'
    },
    {
        icon: <FiCast />,
        title: 'Supplier Management',
        description: 'Supplier Management starts with unique Procurement solution with smart bidding algorithms that enhances their engagement.'
    },
    {
        icon: <FiUsers />,
        title: 'Integrations',
        description: 'Smart Integrations with a centralized dashboard and its flexible to integrate with SAP through 3rd party approval.'
    },
    {
        icon: <FiMonitor />,
        title: 'Vendor Management',
        description: 'Quicker registration by accessing their vendor information through Multilingual catalogues. Rate your vendors using KPI’s.'
    },
    {
        icon: <FiCast />,
        title: 'Mobile',
        description: 'Run your eProcurement seamlessly on desktop, iOS and Android. Request, approve or receive PO in a click through Mobile and gets notified.'
    },
]


class ProductERPDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--57" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Procurement Management System</h2>
                                    <p>Cloud / On-premises</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/procurement1.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Procurement Management System</h4>
                                                    <p>Procurement management is much more than a functional area of a business that buys inventory. Procurement management is a strategic function within an organization. From sourcing quality products to vendor management and controlling costs to ensuring the correct quantities of inventory are available to be sold to customers, purchasing management is key to the success of an inventory-centric organization.</p>
                                                    <p>As with most strategic organizational processes, purchasing management affects all areas within a company and requires people, systems, technology, and discipline. In return, a procurement management system can be the profitability difference-maker. Having a controlled cost of goods and the right products in stock in the correct quantities is a key factor in achieving customer satisfaction and organizational growth.</p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">

                                                        <li>Quality Control.</li>
                                                        <li>Purchase, Inventory.</li>
                                                        <li>Multi Tenancy, Multi Language.</li>
                                                        <li>TenderManagemen, Vendor Management.</li>
                                                        <li>Budget Management, Budget Management.</li>
                                                    </ul>
                                                    <br></br>
                                                    <div>
                                                        <h4 className="title"> Benefits of Procurement Management</h4>
                                                        <ul className="liststyle">
                                                            <li>Improve cost savings.</li>
                                                            <li>Improve supplier management.</li>
                                                            <li>Increase influence over spend.</li>
                                                            <li>Standardize and streamline the contract lifecycle.</li>
                                                            <li>Improve efficiency and automate procurement processes.</li>



                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/procurement2.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='8HMWBL0JJys' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ProductERPDetails;