import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Omnichannel support',
        description: 'Freshdesk helps you stay on top of customer conversations coming in via email, phone, and social media from a single platform.'
    },
    {
        icon: <FiMonitor />,
        title: 'Effortless remote support',
        description: 'Freshdesk allows your team to hold conversations from wherever you are - all you need is an internet connection and a laptop or cellphone.'
    },
    {
        icon: <FiCast />,
        title: 'Intelligent automations',
        description: 'Cut down on repetitive tasks by automating the process of ticket assignment, and free up your time to solve complex customer issues.'
    },
    {
        icon: <FiUsers />,
        title: 'Intuitive reporting',
        description: 'Track team performance, customer satisfaction and identify bottlenecks using Freshdesks customizable in-built reports.'
    },
    {
        icon: <FiMonitor />,
        title: 'Seamless self-service',
        description: 'Deflect commonly asked questions by building a knowledge base with FAQs and help-guides that your customers can use to find answers on their own.'
    },
    {
        icon: <FiCast />,
        title: '650+ integrations',
        description: 'Integrate everything your support team needs into your helpdesk - from payment management to ERO, and much more.'
    },
]


class TicketingSystem extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--48" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">TICKETING SOFTWARE</h2>
                                    <p>It’s easier to prioritize track </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/ticket1.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>TICKETING SOFTWARE</h3>
                                                    <p>Ticketing software are also known as an ticketing system, is a software program that enables organizations to resolve their internal support queries by managing and streamlining the process of issue resolution.They handle individual elements called tickets that provide a context of what issue the requester is facing, along with other data like category, priority, etc. Tickets act as a documentation of a particular problem, its current status, and other associated information. </p>
                                                    <p>A ticketing system collects and manages all customer support interactions from different platforms, including phone, live chat, email, and social media. This management system enables IT, support agents, to route various types of tickets to the correct person, resource, or department to obtain the fastest resolution, these tickets are routed to the ticketing software where they are categorized, prioritized, and assigned to different agents according to the organizational norms.</p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">
                                                        <li>Full customization.</li>
                                                        <li>Workflow automation.</li>
                                                        <li>Team empowerment.</li>
                                                        <li>Personalized ticket pages.</li>
                                                        <li>Ticket categories and tags.</li>
                                                        <li>Multi-channel accessibility.</li>
                                                        <li>Support for multiple languages.</li>
                                                    </ul>
                                                    <h4 className="title">Benefits of Ticketing Systems</h4>
                                                    <ul className="liststyle">
                                                        <li>Centralized System.</li>
                                                        <li>Better Customer Experience.</li>
                                                        <li>Enables Prioritization of Work.</li>
                                                        <li>In-Built tracking and reporting.</li>
                                                        <li>Automatic management of tasks.</li>
                                                        <li>Drives Staff Accountability and Training.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/ticket2.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='k-OhPGgh80A' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default TicketingSystem;