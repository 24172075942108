// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Demo from './page-demo/Demo';
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax';
import HomePortfolio from './home/HomePortfolio';
import DigitalAgency from './home/DigitalAgency';
import CreativeAgency from './home/CreativeAgency';
import PersonalPortfolio from './home/PersonalPortfolio';
import Business from './home/Business';
import StudioAgency from './home/StudioAgency';
import PortfolioLanding from './home/PortfolioLanding';
import CreativeLanding from './home/CreativeLanding';
import HomeParticles from './home/HomeParticles';
import CreativePortfolio from './home/CreativePortfolio';
import DesignerPortfolio from './home/DesignerPortfolio';
import InteriorLanding from './home/Interior';
import CorporateBusiness from './home/CorporateBusiness';
import InteractiveAgency from './home/InteractiveAgency';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";


// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import ProductERPDetails from './elements/products/ProductERPDetails';
import ProductCRMDetails from './elements/products/ProductCRMDetails';
import ProductHRMSDetails from './elements/products/ProductHRMSDetails';
import RetailsBillingSoftware from './elements/products/ProductRetailsBillingSoftware';
import SuparmarketBillingSoftware from './elements/products/SuparmarketBillingSoftware';
import ProductPropertyManagement from './elements/products/ProductPropertyManagement';
import TexttileBillingSoftware from './elements/products/TexttileBillingSoftware';
import JewelleryBillingSoftware from './elements/products/JewelleryBillingSoftware';
import WholesaleBillingSoftware from './elements/products/WholesaleBillingSoftare';
import MedicalBillingSoftware from './elements/products/MedicalBillingSoftware';
import BakeryBillingSoftware from './elements/products/BakeryBillingSoftware';
import MobileappDevelopment from './elements/Mobileappdetails';
import WebDevelopment from './elements/WebDevelopment';
import ArtificialIntelligence from './elements/ArtificialIntelligence';
import CAMS from './elements/products/CAMS';
import CHATBOT from './elements/products/CHATBOT.jsx';
import BillingSoftware from './elements/products/BillingSoftware';
import sales from './elements/products/Sales';
import Leads from './elements/products/Leads';
import DigitalDocs from './elements/products/DigitalDocs';
import VMS from './elements/products/Vms';
import MyMeeting from './elements/products/MyMeeting';
import InternalChat from './elements/products/InternalChat';
import Inventory from './elements/products/Inventory';
import TicketingSystem from './elements/products/TicketingSystem';
import BusinessProcess from './elements/products/BusinessProcess';
import StudentManagement from './elements/products/StudentManagement';
import Product from './elements/Product';
import HospitalManagmentSystem from './elements/products/HospitalManagmentSystem';
import DigitalMarketing from './elements/DigitalMarketing';
import TermsandConditions from './elements/TermsandConditions';
import Refundandcancellation from './elements/RefundandCancellation';
import Privacypolicy from './elements/PrivacyPolicy';
class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Business}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/demo`} component={Demo}/> 

                        <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/interior-landing`} component={InteriorLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/corporate-business`} component={CorporateBusiness}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/interactive-agency`} component={InteractiveAgency}/>

                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/product`} component={Product}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/erp`} component={ProductERPDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/crm`} component={ProductCRMDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/hrms`} component={ProductHRMSDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-cams`} component={CAMS}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-chatbot`} component={CHATBOT}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-billingsoftware`} component={BillingSoftware}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-sales`} component={sales}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-leads`} component={Leads}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-digitaldocs`} component={DigitalDocs}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-propertymanagement`} component={ProductPropertyManagement}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-business-process-management`} component={BusinessProcess}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/erp`} component={ProductERPDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-student-management`} component={StudentManagement}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-vms`} component={VMS}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-mymeeting`} component={MyMeeting}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-internalchat`} component={InternalChat} />
                        <Route exact path={`${process.env.PUBLIC_URL}/product-inventory`} component={Inventory} />
                        <Route exact path={`${process.env.PUBLIC_URL}/product-ticketingsystem`} component={TicketingSystem} />
                        <Route exact path={`${process.env.PUBLIC_URL}/hospitalmanagmentsystem`} component={HospitalManagmentSystem} />
                        <Route exact path={`${process.env.PUBLIC_URL}/product/retailbilling`} component={RetailsBillingSoftware}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/supermarketbilling`} component={SuparmarketBillingSoftware}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/texttilebilling`} component={TexttileBillingSoftware}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/jewellerybilling`} component={JewelleryBillingSoftware}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/wholesalebilling`} component={WholesaleBillingSoftware}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/medicalbilling`} component={MedicalBillingSoftware}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/bakerybilling`} component={BakeryBillingSoftware}/>
                      

                        

                        {/* Element Layot */}

                        <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-mobileappdevelopment`} component={MobileappDevelopment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-webDeveploment`} component={WebDevelopment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/digital-marketing`} component={DigitalMarketing}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-artificialIntelligence`} component={ArtificialIntelligence}/>                        
                        <Route exact path={`${process.env.PUBLIC_URL}/service-termsandconditions`} component={TermsandConditions}/>                       
                        <Route exact path={`${process.env.PUBLIC_URL}/service-refundandcancellation`} component={Refundandcancellation}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-privacypolicy`} component={Privacypolicy}/>
                      
                       


                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>

                        {/* Blocks Elements  */}
                        <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/client-logo`} component={Brand}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/>


                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();