import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";
import SimpleSlider from "../elements/common/Slickcarousel";
//import Scrollauto from "../elements/common/Auroscroll";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Mobile App Development',
        description: 'Marketing reporting is the process of gathering and analyzing marketing metrics to inform future marketing decisions, strategies, and performance. Marketing reports uncover meaningful, actionable goals.'
    },
    {
        icon: <FiMonitor />,
        title: 'Web Development',
        description: 'Mobile application development is the process of creating software applications that run on a mobile device, and a typical mobile application utilizes a network connection to work with remote computing resources.'
    },
    {
        icon: <FiCast />,
        title: 'Artificial Intelligence ',
        description: 'Billing software is a tool that automates invoice generation for goods and services rendered. The integrated tools create a list of products and services and their related costs per alculations respective recipient.'
    },
]

class DigitalMarketing extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">DIGITAL MARKETING</h2>
                                    <p>Supplement your affiliate marketing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/pm.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Digital Marketing</h4>
                                                    <p>Now, the world has moved towards digitization and traditional marketing methods are becoming obsolete now.
                                                        That’s a fact as things are now changing dramatically and the businesses also have to think accordingly.
                                                    </p>

                                                    <h4 className="title">Feature</h4>
                                                    <ul className="liststyle">
                                                        <li>Costs.</li>
                                                        <li>Longevity.</li>
                                                        <li>Segmentation.</li>
                                                        <li>Buying intent.</li>
                                                        <li>Customer Engagement.</li>
                                                        <li>Measuring Profitability.</li>
                                                        <li>Defining the Target Audience.</li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    {/* <p>but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing</p> */}
                                                    <p>Nowadays everyone knows what digital marketing is and how it influences businesses to sell products conveniently compared to traditional marketing tactics. Digital Marketing means marketing goods and services via any digital or electronic media.</p>
                                                    <h4 className="title">Benefits Of Digital Marketing</h4>
                                                    <ul className="liststyle">
                                                        <li>Gain Brand Credibility.</li>
                                                        <li>Target the Right Audience.</li>
                                                        <li>Accurately Measure All Results.</li>
                                                        <li>Generate a Consistent Lead Pipeline.</li>
                                                        <li>Engage the Customer at Every Buying Stage.</li>
                                                        <li>Optimize and Obtain Better Conversion Rates.</li>
                                                        <li>Increase Customer Loyalty with Frequent Communications.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/propertymanagement.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='7W0G8FdQ_MM' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>

                                        </div>
                                        <br></br>
                                        <div>
                                            <SimpleSlider />
                                        </div>
                                        {/* <br></br>
                                        <div>
                                            <Scrollauto />
                                        </div> */}
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default DigitalMarketing;