import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const PricingTable = () => {
    return (
        <>
            <PageHelmet pageTitle='Pricing Table' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Pricing Table'} />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">SuperMarket Billing Software</h4>
                                            <div className="pricing">
                                                <span className="price">15,000</span>
                                                <span className="subtitle">RS Per Year</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Easy & Fast Billing</li>
                                                <li><FiCheck />Discounts & Schemes</li>
                                                <li><FiCheck />Re-Order Management</li>
                                                <li><FiCheck />Barcode Management</li>
                                                <li><FiCheck />Inventory Management</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Textile Billing Software</h4>
                                            <div className="pricing">
                                                <span className="price">10,000</span>
                                                <span className="subtitle">RS Per Year</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Easy & Fast Billing</li>
                                                <li><FiCheck />Discounts & Schemes</li>
                                                <li><FiCheck /> Barcode Management</li>
                                                <li><FiCheck /> Inventory Management</li>
                                                <li><FiCheck />Re-Order Management</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Jewellery Billing Software</h4>
                                            <div className="pricing">
                                                <span className="price">10,000</span>
                                                <span className="subtitle">RS Per Year</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Online Billing</li>
                                                <li><FiCheck />Easy & Fast Billing</li>
                                                <li><FiCheck />Discounts & Schemes</li>
                                                <li><FiCheck />Inventory Management</li>
                                                <li><FiCheck />Wages & Damage invoice</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </main> <main className="page-wrapper">
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Mobile App Development</h4>
                                            <div className="pricing">
                                                <span className="price">10,000</span>
                                                <span className="subtitle">RS Per year</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Security</li>
                                                <li><FiCheck />Users & Accounts</li>
                                                <li><FiCheck />Marketing & Reporting </li>
                                                <li><FiCheck />Software Development</li>
                                                <li><FiCheck />Payment and ecommerce</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Digital Marketing & SEO</h4>
                                            <div className="pricing">
                                                <span className="price">5,000</span>
                                                <span className="subtitle">RS Per Year</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Costs</li>
                                                <li><FiCheck />Longevity</li>
                                                <li><FiCheck />Segmentation</li>
                                                <li><FiCheck />Customer Engagement</li>
                                                <li><FiCheck />Measuring Profitability</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Web Development</h4>
                                            <div className="pricing">
                                                <span className="price">10,000</span>
                                                <span className="subtitle">RS Per Year</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Fast Load Times</li>
                                                <li><FiCheck />Responsive Design</li>
                                                <li><FiCheck />Browser Consistency</li>
                                                <li><FiCheck />Mobile Compatibility</li>
                                                <li><FiCheck />Accessible to All Users</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>

    )
}
export default PricingTable;