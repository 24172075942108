import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
  {
    icon: <FiUsers />,
    title: 'Mobile App Development',
    description: 'Marketing reporting is the process of gathering and analyzing marketing metrics to inform future marketing decisions, strategies, and performance. Marketing reports uncover meaningful, actionable goals.'
  },
  {
    icon: <FiMonitor />,
    title: 'Web Development',
    description: 'Mobile application development is the process of creating software applications that run on a mobile device, and a typical mobile application utilizes a network connection to work with remote computing resources.'
  },
  {
    icon: <FiCast />,
    title: 'Artificial Intelligence ',
    description: 'Billing software is a tool that automates invoice generation for goods and services rendered. The integrated tools create a list of products and services and their related costs per alculations respective recipient.'
  },
]

class StudentManagement extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--58"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Student Management</h2>
                  <p> It is defined as a skillset</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}

        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/sm1.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h3>Student Management</h3>
                          <p>
                            Here are the most important features that every Student Information System or School Management System should provide. School Management Software brings the best of
                            technology to provide 360 degree experience to
                            schools which not only improves productivity of
                            administrative staff but improves experience and
                            productivity of all the stakeholders- principal,
                            management, teachers, administration staff, students
                            and parents.
                          </p>
                          <h4 className="title">Features</h4>
                          <ul className="liststyle">
                            <li>Management Dashboard</li>
                            <li>Quick communication, </li>
                            <li>Lesson progress tracking</li>
                            <li>Class wise performance tracking</li>
                            <li>Staff records tracking, Staff records tracking</li>
                            <li>Data analysis and reports, Teachers activity analysis</li>


                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row service-one-wrapper">
                      {ServiceList.map((val, i) => (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                          <a href="/service-details">
                            <div className="service service__style--2">
                              <div className="icon">
                                {val.icon}
                              </div>
                              <div className="content">
                                <h3 className="title">{val.title}</h3>
                                <p>{val.description}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <br></br>
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">

                          <h4 className="title">Benefits of Student Management System</h4>
                          <ul className="liststyle">
                            <li>Easy Access to All.</li>
                            <li>Managing Timetables.</li>
                            <li>Better Communication.</li>
                            <li>Enhanced Productivity</li>
                            <li>Better Performance by Students.</li>
                            <li>Complete Tracking of the Students.</li>
                            <li>Simplifying & Streamlining all Tasks.</li>
                            <li>Cloud-Based SIS for Enhanced Security.</li>
                            <li>Better Utilization of Time & Resources.</li>
                            <li> Centralized Database for Information Management.</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/sm2.jpg"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="5QQtqTZ-mEY"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default StudentManagement;
