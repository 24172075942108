import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
//import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiChevronUp } from "react-icons/fi";

class TermsandConditions extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">TERMS & CONDITIONS</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}

        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}

                    {/* End Single Area */}

                    {/* Start Single Area */}

                    <div>
                      <div>
                        <div>
                          {/* <p>but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing</p> */}
                          <h4 className="title">Eligibility to use our site</h4>
                          <p>
                            Use of the Site is available only to persons who can
                            legally enter into contracts under applicable laws.
                            Persons who are "incompetent to contract", within
                            the meaning of the Indian Contract Act, 1872
                            including un-discharged insolvents etc. are not
                            eligible to use the Site. "aathisoft.com"
                            reserves the right to terminate your access to the
                            Site if it discovers that you are under the age of
                            18 years or suffers from any other disability, as
                            recognized under Indian Contract Act, 1872.
                          </p>
                          <h4 className="title">Membership</h4>
                          <p>
                            Although it's not essential to have an account to
                            shop with "aathisoft.com", you can shop as a
                            guest. As a member, you agree to provide true,
                            accurate, current, and complete information about
                            yourself as prompted by the site's registration
                            form. Registration where prohibited under any law
                            shall be void. "aathisoft.com" reserves the
                            right to revoke or terminate your registration for
                            any reason at any time, without notice.
                          </p>
                          <h4>Electronic Communications</h4>
                          <p>
                            When you use the site or send emails or other data,
                            information or communicate to us, you agree and
                            understand that you are communicating with us
                            electronically and give your consent to receive
                            communications electronically from us periodically,
                            when required.
                          </p>
                          <h4>Reviews, Feedback, Submissions</h4>
                          <p>
                            All reviews, comments, feedback, postcards,
                            suggestions, ideas, and other submissions disclosed,
                            submitted or offered to "aathisoft.com"
                            directly or otherwise disclosed, submitted or
                            offered in connection with your use of this Site
                            (collectively referred to "Comments") will remain
                            "aathisoft.com" property. Such disclosure,
                            submission or offer of any comments shall constitute
                            an assignment to "aathisoft.com" of all
                            worldwide rights, titles and interests in all
                            copyrights and other intellectual properties in the
                            comments,thus, it exclusively owns all such rights,
                            titles and interests and shall not be limited in any
                            way in its use, commercial or otherwise.
                          </p>
                          <p>
                            "aathisoft.com" will be entitled to use,
                            reproduce, disclose, modify, adapt, create
                            derivative works from, publish, display and
                            distribute any comments you submit for any purpose
                            whatsoever, without restriction and without
                            compensating you in any way. "aathisoft.com" is
                            and shall be under no obligation (1) to maintain any
                            Comments in confidence; or (2) to pay you any
                            compensation for any Comments; or (3) to respond to
                            any Comments. You agree that any comments submitted
                            by you to the Site will not violate this policy or
                            any right of any third party, including copyright,
                            trademark, privacy or other personal or proprietary
                            right(s), and will not cause injury to any person or
                            entity.
                          </p>
                          <p>
                            You further agree that no comments submitted by you
                            to the site will be libelous or otherwise unlawful,
                            threatening, abusive or obscene material, or contain
                            software viruses, political campaigning, commercial
                            solicitation, chain letters, mass mailings or any
                            form of "spam". "aathisoft.com" does not
                            regularly review posted comments, but does reserve
                            the right (but not the obligation) to monitor and
                            edit or remove any comment submitted to the Site.
                            You grant "aathisoft.com" the right to use the
                            name that you submit in connection with any of the
                            posted comments.
                          </p>
                          <p>
                            You agree not to use a false email address,
                            impersonate any person or entity, or otherwise
                            mislead as to the origin of any Comments you submit.
                            You are and shall remain solely responsible for the
                            content of any comments you make and you agree to
                            indemnify "aathisoft.com" and its affiliates
                            for all claims resulting from any Comments you
                            submit, we take no responsibility and assume no
                            liability for any comments submitted by you or any
                            third party.
                          </p>
                          <h4>
                            Accuracy of Content/ Information of Products on the
                            Web Site
                          </h4>
                          <p>
                            While "aathisoft.com" strives to provide
                            accurate product and pricing information,
                            typographical errors may occur. In the event that a
                            product is listed at an incorrect price or with
                            incorrect information due to an error in pricing or
                            product information, "aathisoft.com" shall have
                            the right, on our sole discretion, to modify the
                            price of the products, or information of the
                            products or to refuse or cancel any orders placed
                            for that product, unless the product has already
                            been dispatched. In the event.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default TermsandConditions;
