import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";

import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Contact Management',
        description: 'Organize contacts and gathers customer data for use in marketing purpose. Customized layout to suit your needs with fields added in a logical way.'
    },
    {
        icon: <FiMonitor />,
        title: 'Lead Management',
        description: 'Incoming leads are qualified, analyzed and nurtured can be converted into business opportunities.'
    },
    {
        icon: <FiCast />,
        title: 'Analytical Reports',
        description: 'Reports can be customized, improves the decision-making process and integrates the real-time metrics.'
    },
    {
        icon: <FiUsers />,
        title: 'Workflow Automation',
        description: 'Every time, team follows up with the lead for conversion. Integrates this feature to automate the sales routine.'
    },
    {
        icon: <FiMonitor />,
        title: 'Mobile CRM',
        description: 'Field employees can access all CRM features, receives alerts and notifications in a centralized platform through mobile phone or tablet.'
    },
    {
        icon: <FiCast />,
        title: 'Customizable',
        description: 'Customize their functionality and algorithms by adding new or existing CRM based on the requirements.'
    },
]

class ProductCRMDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--60" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">CRM</h2>
                                    <p>Fresh From The Press Discription</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/crm1.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3 className="title">Customer Relationship Management</h3>
                                                    <p>CRM Software (Customer Relationship Management) solution has been helping all kinds of small and medium businesses to organise, automate and improve their business.Aathisoft makes your business run transparently and intelligently which projects your brand image and win your customers satisfaction. Aathisoft CRM software is a unique solution that addresses the needs of Sales & Marketing, Customer Service, Business Intelligence, Call Centre, Back Office, Product Management, Business Process Management, IT and other departments that are either directly or indirectly connected to Customers and needs information on the customers and their transactions.</p>
                                                    <p>A CRM system gives everyone — from sales, customer service, business development, recruiting, marketing, or any other line of business — a better way to manage the external interactions and relationships that drive success. A CRM tool lets you store customer and prospect contact information, identify sales opportunities, record service issues, and manage marketing campaigns, all in one central location — and make information about every customer interaction available to anyone at your company who might need it. With visibility and easy access to data, it's easier to collaborate and increase productivity. Everyone in your company can see how customers have been communicated with, what they’ve bought, when they last purchased, what they paid.</p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">
                                                        <li>Workflow automation.</li>
                                                        <li>Customization.</li>
                                                        <li>Third-party integration.</li>
                                                        <li>Employee tracking</li>
                                                        <li>Social media.</li>
                                                        <li>Real-time data.</li>
                                                    </ul>
                                                    <h4 className="title">Usage & Benefits of CRM</h4>
                                                    <ul className="liststyle">
                                                        <li>Better segmentation.</li>
                                                        <li>Better customer retention.</li>
                                                        <li>Better anticipation of needs.</li>
                                                        <li>Better knowledge of customers.</li>
                                                        <li>Better protection of data privacy.</li>
                                                        <li>Better and speedier communication.</li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/crm2.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='8HMWBL0JJys' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ProductCRMDetails;