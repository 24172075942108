import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'IPD Management',
        description: 'A complete Journey of patient, right from admission to payment and discharge. It also enables patient registration and Bed management.'
    },
    {
        icon: <FiMonitor />,
        title: 'OPD Management',
        description: 'Basically termed as Outpatient Department, it is a complete hospital information system that focuses on  Outpatients registration, treatments, billing, and reporting with details of patient information.'
    },
    {
        icon: <FiCast />,
        title: 'Inventory Management',
        description: 'This plays a vital role in the hospital management system. MocDoc’s inventory management helps to track all of the medical resources such as equipment, medicines, and patient’s health data.'
    },
    {
        icon: <FiUsers />,
        title: 'Pharmaceutical ',
        description: 'Pharmaceutical  reporting is the process of gathering and analyzing marketing metrics to inform future marketing decisions, strategies, and performance. Marketing reports uncover meaningful, actionable goals.'
    },
    {
        icon: <FiMonitor />,
        title: 'Custom user',
        description: 'Our pharmacy with providing the retail sales of medical supervision to OPD patients, customers and also helps with the issue of medicines to the hospital inpatients. '
    },
    {
        icon: <FiCast />,
        title: 'Billing Module',
        description: 'Billing software is a tool that automates invoice generation for goods and services rendered. The integrated tools create a list of products and services and their related costs per alculations respective recipient.'
    },
]


class HospitalManagmentSystem extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--54" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">HOSPITAL MANAGEMENT SYSTEM</h2>
                                    <p>The healthcare simulation field </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/hms1.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>Hospital Managment System</h3>
                                                    <p>Hospital management system is a computer system that helps manage the information related to health care and aids in the job completion of health care providers effectively. They manage the data related to all departments of healthcare such as.</p>
                                                    <p>The hospital management system helps register complete patient information. It captures and stores the medical history, treatment required, details of their previous visits, upcoming appointments if any, reports, insurance details and more. It helps eliminate the need to get these details on every visit.</p>
                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">
                                                        <li>Billing.</li>
                                                        <li>Patients.</li>
                                                        <li>Administration.</li>
                                                        <li>Labs & Medication.</li>
                                                        <li>Inventory & Billing.</li>
                                                        <li>Sheduling & Incident.</li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Benefits of Hospital
                                                        <br></br>Management System</h4>
                                                    <ul className="liststyle">
                                                        <li>Improve data security.</li>
                                                        <li>Achieve good quality ratings.</li>
                                                        <li>Better revenue management.</li>
                                                        <li>Improved clinical decision-making.</li>
                                                        <li>Avoid errors and track every single detail.</li>
                                                        <li>Establish your hospital as technically advanced</li>
                                                        <li>Establish your hospital as technically advanced.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/hms2.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='PwX8GG5zaWk' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default HospitalManagmentSystem;