import React, { Component } from "react";
//import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'service',
        link:"/service-details"
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Website Development',
        link:"/service-webDeveploment"
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Marketing & Reporting',
        link:"/service-details"
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Mobile App Development',
        link:"/service-mobileappdevelopment"
    },
    {
        image: 'image-5',
        category: 'Development',
        title: 'Inventory Management',
        link:"/product-inventory"
    },
    {
        image: 'image-6',
        category: 'Development',
        title: 'Billing software',
        link:"/product-billingsoftware"
    },
    {
        image: 'image-7',
        category: 'Development',
        title: 'Procurement Management',
        link:"/product-propertymanagement"
    },
    {
        image: 'image-8',
        category: 'Development',
        title: 'Digital Marketing',
        link:"/digital-marketing"
    },
    {
        image: 'image-9',
        category: 'Development',
        title: 'Software Development',
        link:"/service-webDeveploment"
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href="/portfolio-details">{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.link}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            {/* <Link className="link-overlay" to="/portfolio-details"></Link> */}
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;