import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Contact Management',
        description: 'Organize contacts and gathers customer data for use in marketing purpose. Customized layout to suit your needs with fields added in a logical way.'
    },
    {
        icon: <FiMonitor />,
        title: 'Lead Management',
        description: 'Incoming leads are qualified, analyzed and nurtured can be converted into business opportunities.'
    },
    {
        icon: <FiCast />,
        title: 'Analytical Reports',
        description: 'Reports can be customized, improves the decision-making process and integrates the real-time metrics.'
    },
    {
        icon: <FiUsers />,
        title: 'Workflow Automation',
        description: 'Every time, team follows up with the lead for conversion. Integrates this feature to automate the sales routine.'
    },
    {
        icon: <FiMonitor />,
        title: 'Mobile CRM',
        description: 'Field employees can access all CRM features, receives alerts and notifications in a centralized platform through mobile phone or tablet.'
    },
    {
        icon: <FiCast />,
        title: 'Customizable',
        description: 'Customize their functionality and algorithms by adding new or existing CRM based on the requirements.'
    },
]


class CAMS extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--38" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Asset Management Software</h2>
                                    <p> The combination of practices</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>CAMS</h4>
                                                    <p>Computerized Asset Management Software and Computerized Maintenance Management Software are one of the most advanced solution available in the market for managing any type of objects viz. Fixed Assets, Plant Machineries, Virtual Assets, Properties etc. Asset Management Software India is developed with an objective to manage the Assets with minimum efforts, to ensure High Availability, Lower Operational Cost and Longer shelf life more than 100+ man-years of experience made CAMS readily deployable for any Industry.</p>

                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">
                                                        <li>Quality. CAMS is an ISO 9001:2008 certified establishment. ...</li>
                                                        <li>Transfer Agency Services. The responsibilities of active mutual fund companies are many. ...</li>
                                                        <li>Risk Management. ...</li>
                                                        <li>Access. ...</li>
                                                        <li>Strong web presence. .</li>
                                                        <li>Technology Driven. ...</li>
                                                        <li>Confidentiality. ...</li>
                                                        <li>E-KYC Facility.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Our Working Process</h4>
                                                    <ul className="liststyle">
                                                        <li>Yet this above sewed flirted opened ouch</li>
                                                        <li>Goldfinch realistic sporadic ingenuous</li>
                                                        <li>Abominable this abidin far successfully then like piquan</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='bi4tLBueVnQ' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default CAMS;