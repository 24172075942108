const BlogContent = [
    {
        images: '01',
        title: ' Kavitha',
        category: 'CEO'
    },
    {
        images: '02',
        title: 'Thiruveangadam',
        category: 'CTO'
    },

    {
        images: '03',
        title: 'Gayathiri',
        category: 'CPO'
    },
    {
        images: '04',
        title: 'Mounica',
        category: 'Development'
    },
    {
        images: '01',
        title: 'Sriram',
        category: 'Management'
    },
    {
        images: '02',
        title: 'Mubarak',
        category: 'Design'
    },
]

export default BlogContent;