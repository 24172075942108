import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
//import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { FiCast, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Real-time Reporting',
        description: 'Save atleast 4 hours on a daily basis and invest your time in Business Growth & RnD'
    },
    {
        icon: <FiMonitor />,
        title: 'Implements 100%',
        description: '95% users say that the best thing about TranZact is that it’s extremely easy to understand and used.'
    },
    {
        icon: <FiCast />,
        title: 'Flexible Integrations',
        description: 'Integrate your Whatsapp and Email for better communication with Customers.'
    },
]

class Inventory extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--47" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">INVENTORY</h2>
                                    <p>Itemized list of current assets</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/inventory1.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h3>INVENTORY</h3>
                                                    <p>Inventory management encompasses much more than simply keeping track of what you keep in your warehouse or retail storeroom. Inventory management also includes keeping track of what's in your parts department, including individual parts and the combinations of those parts used to build other products and services. Inventory management also involves finding out what your supply partners or your best customers have in stock. For small to midsize businesses (SMBs), keeping track of all these items can get difficult quickly if you're just using a spreadsheet to do so.</p>
                                                    <p>An inventory management system optimizes inventory levels and ensures product availability across multiple channels. It provides a single, real-time view of items, inventory and orders across all locations and selling channels. This enables businesses to carry less inventory on hand and frees up cash to be used in other parts of the business. An inventory management system helps keep inventory costs low while delivering on customer expectations.</p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}

                                        <div className="row service-one-wrapper">
                                            {ServiceList.map((val, i) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                    <a href="/service-details">
                                                        <div className="service service__style--2">
                                                            <div className="icon">
                                                                {val.icon}
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="title">{val.title}</h3>
                                                                <p>{val.description}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        <br></br>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Features</h4>
                                                    <ul className="liststyle">

                                                        <li>Ease of Use</li>
                                                        <li>Secured Data</li>
                                                        <li>Quality Control</li>
                                                        <li>Stock Management</li>
                                                        <li>Easy sales tracking</li>
                                                        <li>Track items, Reports</li>
                                                        <li>Works with Common Hardware Options</li>
                                                    </ul>
                                                    <h4 className="title">Benefits of Efficient Inventory Management </h4>
                                                    <ul className="liststyle">
                                                        <li>Save Time and Money.</li>
                                                        <li>Improved Warehouse Productivity.</li>
                                                        <li>Improved Warehouse Organization.</li>
                                                        <li>Improved Accuracy of Inventory Orders.</li>
                                                        <li>Improved Customer Retention and Engagement.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/inventory2.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='bi4tLBueVnQ' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Inventory;