import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);
  let hostName = "https://aathisoft-mail-api.azurewebsites.net/api";//"https://eapi-dev.herokuapp.com"; //"http://localhost:3000";//;
  const sendEmailNew = (e) => {
    debugger;
    e.preventDefault();
    var dataNew= {
      recipientEmail:e.target.elements.email.value,
      subject:e.target.elements.subject.value,
      htmlMessage:e.target.elements.message.value,
      CustomerName:e.target.elements.fullname.value,
      ContactNumber:e.target.elements.phone.value,
   }
    var data = {
      deleted: false,
      deletedOn: new Date().toISOString(),
      deletedBy: "string",
      createdOn: new Date().toISOString(),
      modifiedOn: new Date().toISOString(),
      ip_address: "string",
      createdBy: 0,
      modifiedBy: 0,
      tenantId: 10,
      applicationName: "AATHISOFT_WEBSITE",
      yourName: e.target.elements.fullname.value,
      yourEmail: e.target.elements.email.value,
      phoneNumber: e.target.elements.phone.value,
      subject: e.target.elements.subject.value,
      yourMessage: e.target.elements.message.value,
    };
    const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "multipart/form-data",
        "accept":"*/*",
     },
      body: JSON.stringify(dataNew),
    };
    fetch(hostName + "/Email/SendEnquiry", requestOptions)
      .then((data) => {
        if (data.status === 200) {
          showresult(true);
          e.target.reset();

        }
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        showresult(false);
      });
  };
  const sendEmail = (e) => {
    const serviceID = "serviceId_sentthill"; //'service_p4x3hv8';
    const templateID = "template_3cibias"; //'template_jgfr42f'; //
    const publicKey = "xvnmTBnVdgHF6loyM"; //'user_jrfTH2e0Ely35ZCVFdT9S';//'';
    e.preventDefault();
    emailjs.sendForm(serviceID, templateID, e.target, publicKey).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form action="" onSubmit={sendEmailNew}>
      <div className="rn-form-group">
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>

      <div className="rn-form-group">
        <input type="email" name="email" placeholder="Your Email" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Phone Number" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="subject" placeholder="Subject" required />
      </div>

      <div className="rn-form-group">
        <textarea name="message" placeholder="Your Message" required></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Submit Now
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
